.tab{
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-self: center;
    background: white;
    padding: 0 0 6px;
    // border-top: 1px solid #E5E5E5;
    z-index: 1000;
    .am-tab-bar{
        width: 100%;
    }
}

.tab-item{
    color: #C3C3C3;
}

.tab-active{
    color: #D7AA78 !important;
}

.tab-item-icon{
    width: 22px;
    height: 19px;
    margin: 0 auto;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.tab-item-name{
    margin-top: 4px;
    text-align: center;
}