.vote-header{
    margin-left: 18px;
    padding-bottom: 6px;
    .title{
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #414141;
        margin: 20px 0px;
    }
    .center{
        // margin-bottom: 25px;
        .time{
            font-size: 11px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
            margin-bottom: 4px;
        }
        .vote_count{
            font-size: 11px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
        }
    }
    .content{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #414141;
    }
}

.vote-box{
    padding: 18px;
    .box{
        width: 164px;
        height: 198px;
        background: #F2F3F8;
        border-radius: 6px;
        margin: 0;
        overflow: hidden;
        .box-top{
            height: calc(100% - 78px);
            background: #fff;
            .vote-img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .box-bottom{
            width: 100%;
            padding: 12px 0;
            .vote-name{
                text-align: center;
                font-size: 13px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #383838;
                display: flex;
                align-items: center;
                justify-content: center;
                .sec{
                    color: #999;
                }
            }
            .vote-btn{
                width: 102px;
                height: 24px;
                background: linear-gradient(0deg, #ECC391, #CE9958);
                border-radius: 12px;
                line-height: 1.8;
                font-size: 13px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
                margin: 10px auto 0px;
                &.isDisabled{
                    background: #999;
                }
            }
        }
    }
}