#root{
    padding: 0;
}
a:active{
    color: #D6A05E !important;
}
.collectbox{
    .filesList{
        width: 100%;
        height: 100%;
        .adm-tabs-tab-active{
            width: 100%;
            text-align: center;
            color: #D6A05E !important;
            &::after{
                background: #D6A05E !important;
            }
        }
        .adm-tabs-tab-list{
            background: white;
            .adm-tabs-tab-wrapper{
                flex: unset;
                min-width: 70px;
            }
        }
        
    }
    .adm-dialog-button{
        color: #D6A05E !important;
    }
    .creatFiles{
        width: 85%;
        height: 45px;
        text-align: center;
        position: absolute;
        bottom: 0;
    }

    .handleFiles{
        width: 15%;
        height: 45px;
        text-align: center;
        position: absolute;
        bottom: 0;
        right: 0;
    }
    
    .adm-list-item-content-main{
        height: 100%;
        .collectTitle{
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #414141;
            margin-bottom: 12px;
        }
    }
}
.filePopup{
    .fileItem{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .fileName{
            flex: 1;
        }
    }
}