.form_table{
    padding: 0 15px;
    .form_title{
        text-align: center;
    }
    .form_colum{
        display: flex;
        justify-content: space-between;
        column-gap: 20px;
        .ant-row{
            flex: 1 !important;
        }
        .ant-input-number{
            width: 100% !important;
        }
    }
    .ant-card{
        margin-bottom: 10px;
    }
}
.ant-picker-panel-container .ant-picker-panels{
    flex-direction: column !important;
}

.audition_form{
    .userImageBox{
        .adm-list-item-content{
            border: none;
            padding-bottom: 0px;
            .adm-image-uploader{
                height: 80px;
                .adm-space-item{
                    padding-bottom: 0px;
                }
            }
        }
    }
    .adm-form-item-label{
        font-size: 14px;
    }
    .adm-list-item-content{
        font-size: 14px;

        .adm-radio-icon{
            width: 16px;
            height: 16px;
        }

        .adm-radio-checked .adm-radio-icon{
            border-color: #DAAE65;
            background-color: #DAAE65;
        }

        .adm-input,
        .adm-radio-content,
        .adm-text-area{
            font-size: 14px;
        }
        .adm-radio .otherAnswer .adm-input{
            display: block;
        }
    }
    
    .adm-form-footer{
        text-align: center;

        .submit-btn{
            width: 80%;
            height: 40px;
            border-radius: 5px;
            background: linear-gradient(-45deg,#DAAE65, #F8C4866D);
            border: none;
            color: white;
            font-weight: 600;
            margin: 0 auto;
        }
    }
}