$color-grey-2: #999;
$line-height-base: 1;
$spacing-h-lg: 12px;
$at-activity-indicator-font-color: $color-grey-2;
$at-activity-indicator-font-size: 14px;

@mixin display-flex {
  display: flex;
}
@mixin flex($fg: 1, $fs: null, $fb: null) {
  flex: $fg $fs $fb;
  -webkit-box-flex: $fg;
}
@mixin align-self($value: auto) {
  align-self: $value;
}
@mixin absolute-center($pos: absolute) {
  position: $pos;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.at-activity-indicator {
  @include display-flex();

  line-height: $line-height-base;

  /* elements */
  &__body {
    @include flex(0, 0, auto);

    line-height: 0;
  }

  &__content {
    @include flex(0, 0, auto);
    @include align-self(center);

    margin-left: $spacing-h-lg;
    color: $at-activity-indicator-font-color;
    font-size: $at-activity-indicator-font-size;
  }

  /* modifiers */
  &--center {
    @include absolute-center;
  }
}