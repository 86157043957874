.information-box{
    margin-bottom: 14px;
    .adm-list-item-content-main{
        text-align: right;
        p{
            font-size: 15px;
            color: #999999;
        }
        .adm-input-wrapper{
            .adm-input{
                text-align: right;
                &::placeholder{
                    text-align: right;
                }
            }
        }
    }
}
.submitButton{
    width: 100%;
    height: 54px;
    background: linear-gradient(90deg, #FECA8F, #C39B6C);
    box-shadow: 0px 4px 9px 0px rgba(33, 22, 19, 0.13);
    color: white;
    border-radius: 0px;
}