.tabs_box{
    background: white;
    padding-bottom: 56px;
    .ant-tabs-nav{
        margin: 0px !important;
        box-shadow: 0px 0px 28px 17px rgba(0, 0, 0, 0.04);
    }
    .ant-tabs-tab{
        padding: 12px 13px !important;
        font-size: 14px;
        color: #919090;
    }
    
    .ant-tabs-tab+.ant-tabs-tab{
        margin: auto !important;
    }
    
    .ant-tabs-tab-active .ant-tabs-tab-btn{
        color: #D7A05E !important;
    }

    .ant-tabs-ink-bar {
        // visibility: hidden;
        background: #D7A05E;
    }

    .ant-tabs-content-holder{
        background: white;
        // padding: 23px 0px 0px 16px;
    }

    .release{
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background: linear-gradient(-40deg, #C39A6C 0%, #FFCA90 100%);
        box-shadow: 1px 5px 13px 0px rgba(230, 161, 83, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        right: 18px;
        bottom: 86px;
        z-index: 1000;
    }
}

.content-box{
    padding: 23px 0px 0px 16px;

    .ant-tabs-nav{
        padding-right: 16px;
        box-shadow: none;
        margin-bottom: 33px !important;
    
        .ant-tabs-tab{
            min-width: 106px;
            height: 35px;
            background: #F2F2F2 !important;
            border-radius: 5px !important;
            padding: 10px;
            .ant-tabs-tab-btn{
                margin: 0 auto;
                font-size: 14px;
                font-family: PingFang SC;
                color: #262626;
            }
        }

        .ant-tabs-tab-active{
            min-width: 106px;
            height: 35px;
            background: #F7EEE4 !important;
            border: 1px solid #E7AF68 !important;
            padding: 10px;
            .ant-tabs-tab-btn{
                color: #D7A05E;
            }
        }

        .ant-tabs-tab+.ant-tabs-tab{
            margin-left: 12px !important;
        }

        
    }

    .ant-tabs-nav::before{
        border: none !important;
    }

    .my-list{
        .am-list-item{
            padding-left: 0px;
        }
        .am-list-extra{
            max-width: 104px;
            max-height: 70px;
            border-radius: 8px;
            overflow: hidden;
        }
        .am-list-body::before{
            background-color: white !important;
            
        }
        .list-title{
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #414141;
        }
        .list-info{
            display: flex;
            justify-content: space-between;

            .left,
            .right{
                font-size: 12px;
                color: #999999;
                font-family: PingFang SC;
                font-weight: 500;
            }
        }
    }
}

.line{
    margin: 20px auto;
}
