h3{
    text-align: center;
    margin-bottom: 10px;
    padding: 10px;
    background: white;
}
.form{
    .ant-space-vertical{
        flex: 1;
        width: 100%;
    }
}
.form_assess{
    .adm-selector-item-active{
        color: #DAAE65;
        background-color: rgba(218, 173, 101 ,0.32);
    }
    .submit-btn{
        width: 80%;
        height: 40px;
        border-radius: 5px;
        background: linear-gradient(-45deg,#DAAE65, #F8C4866D);
        border: none;
        color: white;
        font-weight: 600;
        margin: 0 auto;
    }
}
.HRAppraise{
    background-color: white;
    padding: 20px 25px;
    .appraise-list{
        margin: 10px 0;
        border-bottom: 1px solid #eee;
    }
}
.listTitle{
    color: #999999;
}
.listContent{
    color: #101010;
}