.form_table{
    .form-box>.am-list-body{
        padding-bottom: 15px !important;
    }
    .word-content{
        textarea{
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            // color: #999999;
            min-height: 75px;
        }
        
    }
    .word-content.am-textarea-item::after{
        height: 0px !important;
    }
    .imageUploadBox{
        .adm-image-uploader-input,
        .adm-image-uploader-upload-button,
        .adm-image-uploader-cell{
            width: 65px;
            height: 65px;
        }
        .adm-image.adm-image-uploader-cell-image{
            width: 65px;
            height: 65px;
            .adm-image-img{
                width: 65px;
                height: 65px;
            }
        }
        
        .adm-image-uploader-upload-button{
            line-height: 65px;
        }
    }
}

.post-to{
    margin-top: 5px;
    .switch-button{
        input:checked + .checkbox::after{
            transform: translateX(25px);
        }
        .checkbox{
            width: 50px;
            height: 25px;
            &::before{
                width: 46px;
                height: 21px;
            }
            &::after{
                width: 21px;
                height: 21px;
            }
        }
    }
}

.post_btn{
    display: inline-block;
    width: 343px;
    height: 44px;
    background: #D9A05A;
    border-radius: 22px;
    margin: 28px auto 0px;
    border: none;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
}
.advanced-setting{
    .checkPermissionBox{
        padding: 10px 15px 0px;
        .adm-checkbox-icon{
            width: 18px;
            height: 18px;
        }
        .adm-checkbox-checked{
            .adm-checkbox-icon{
                border-color: #D9A05A;
                background-color: #D9A05A;
            }
        }
        .adm-checkbox-content{
            font-size: 15px;
            padding-left: 4px;
        }
    }
}