@import "../../assets/styles/vars/colors.scss";

.title-box {
    display: flex;
    justify-content: space-between;
    padding: 18px 18px 3px;
}
.title-box__left,
.title-box__right {
    display: flex;
    align-items: center;
    position: relative;
}
.title-box__left .decorate-line {
    width: 5px;
    height: 14px;
    background-color: $main-color;
    margin-right: 5px;
}
.title-box__left .text {
    font-family: PingFang-SC-Bold;
    font-weight: bold;
    font-size: 14px;
    color: #262626;
    line-height: 1;
    margin-bottom: 0;
}
.title-box__right::after {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}
.title-box__right .text {
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    font-size: 12px;
    color: #919090;
    margin-bottom: 0;
}
.title-box__right .arrow-right {
    width: 7px;
    height: 11px;
    margin-left: 4px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.title-box__right .arrow-right--dark {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAWCAYAAADwza0nAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjEyMDc5Q0ZCOENDMzExRTk5QkUyQjE3Nzg4NEMxQTlFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjEyMDc5Q0ZDOENDMzExRTk5QkUyQjE3Nzg4NEMxQTlFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTIwNzlDRjk4Q0MzMTFFOTlCRTJCMTc3ODg0QzFBOUUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTIwNzlDRkE4Q0MzMTFFOTlCRTJCMTc3ODg0QzFBOUUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7iKjndAAABNklEQVR42ozTzSsFcRTG8Xuvt1wpf4E/RN6SCEXKgmJho0SRbklEkZLyUkpGKQtRUkhX2VvYKnYWdqzExsaC76mjTqeZ38xTn8U903On38yZfBRFOVLEGfZxl8uQgpZu0I9r9GYtXqBDf9fgEgNZiqv4MLNqnGMorfiANry5spx5OFSUPKEJL+ZaJU4wGipKXtGCRzOrwDHGQ0XJO9px78pHmAgVJZ/oRtnM8jjAVKgo+cYgTl15D7P/DyApPxjDFyZNeQdVhZT3LH/cGDNvCBVli67Q5+ZrWEwq1ure9rj5CpaTzljUfe1y8yWs2zP4ktyp080XsOEPb0tlXQCbeWzGPTVJHW7R6q6XsJX0uOu11Gzmv5jDbug9jcSUZnRLgt/jIbZNaTqtZM9Y0o/3WZc5NX8CDAAI2zY2cygjmgAAAABJRU5ErkJggg==");
}
.title-box__right .arrow-right--light {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAASCAYAAABit09LAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjEyRTY3QzU5OENFMzExRTk5OUZBRkUxQzAyQUFEM0I4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjEyRTY3QzVBOENFMzExRTk5OUZBRkUxQzAyQUFEM0I4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTJFNjdDNTc4Q0UzMTFFOTk5RkFGRTFDMDJBQUQzQjgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTJFNjdDNTg4Q0UzMTFFOTk5RkFGRTFDMDJBQUQzQjgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4YkTdoAAAA90lEQVR42nzSv0uCURTG8au1BC7qUGNbu6t/gL/AwRptaGnVQdDBsWadmwSd1EWheImmcA8CwTUkGiNyCIXoe+BpebnXAx84Lzy+773nmIiiqOmc66GLWxeoJNLqb3C1L2hvedLzHQqh4BYXeMUhpsj5glZfKGONFO5x6gtavaOkH50gQsYXtFqiih3OMMeRL2j1jLr6PIY4SAamMUZL/TkaoaDTNFzojP9VQ1+9javvC9q5RnrJApf4jQftpjPddKUJ/MQ/fazZZfGBIj7jZ7RtPGgb36jgLX4Z2+9E+91p7y++Xbf1GatrPIb+PRv1HQxCQ/0TYABYXy1H4NbeMAAAAABJRU5ErkJggg==");
}
