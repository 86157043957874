@import "../../assets/styles/vars/index.scss";
@import "../../assets/styles/mixins/index.scss";

.flash-sale-pages{
    width: 100vw;
    // min-height: 812px;
    min-height: 100vh;
    // background: #e6e6e6;
    position: relative;
    padding: 10% 12px 20%;

    :global{

        .flash-sale-store{
            margin: 3% auto 0px;
            width: 162px;
            height: 25px;
            padding: 5.5px 20px;
            background: linear-gradient(180deg, #FDEBAB, #FFF6E6);
            border-radius: 12.5px;
            text-align: center;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            font-style: italic;
            color: #E29A33;
            text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.66);
            line-height: 1;
        }
        .flash-sale-content{
            width: calc(100% - 24px);
            padding: 5px;
            border: 2px solid #FAE78D;
            border-radius: 15px;
            margin: 94% auto 0px;
            background: radial-gradient(#f55733 60%, #f5573380 30%);

            .content-item{
                width: 100%;
                height: auto;
                background: #FFFFFF;
                border-radius: 5px;
                padding: 0 20px 20px 0;
                position: relative;

                .content-item-top{
                    width: 208px;
                    height: 28px;
                    // background: #FFF7DF;
                    border-radius: 0 10px 10px 0;
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    
                    .item-top-left{
                        height: 100%;
                        padding: 6px 10px;
                        background: linear-gradient(247deg, #FFCD46, #FE782A);
                        box-shadow: 0px 3px 10px 0px rgba(255, 92, 26, 0.45);
                        border-radius: 0 15px 15px 0;
                        font-size: 15px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 1.2;
                        margin-right: 10px;
                    }
                    .item-top-right{
                        height: 100%;
                        font-size: 12px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #F25E04;
                        line-height: 2.3
                    }
                }

                .content-item-bottom{
                    width: 100%;
                    display: flex;
                    align-items: flex-start;

                    .item-pic{
                        width: 110px;
                        height: 110px;
                        border-radius: 10px;
                        margin-left: 15px;
                        margin-right: 10px;
                        
                    }
                    .item-bottom-right{
                        flex: 1;

                        .title{
                            font-size: 15px;
                            font-family: PingFang SC;
                            font-weight: bold;
                            color: #333333;

                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }

                        .cost-price{
                            font-size: 12px;
                            font-family: Adobe Heiti Std;
                            font-weight: normal;
                            text-decoration: line-through;
                            color: #999999;
                        }

                        .price-spike{
                            font-size: 12px;
                            font-family: PingFang-SC-Medium;
                            font-weight: 500;
                            color: #FF1A17;
                            margin-top: 20px;

                            .yuan{
                                font-size: 15px;
                                font-weight: bold;
                                font-family: PingFang-SC-Bold;
                            }
                            .price{
                                font-size: 18px;
                                font-weight: bold;
                                font-family: PingFang-SC-Bold;
                            }
                        }
                    }
                }
                .content-item-remark{
                    padding-left: 15px;
                }

                .flash-sale-item-button{
                    background: linear-gradient(90deg, #fd6d53, #f71922);
                    position: absolute;
                    right: 15px;
                    bottom: 20px;
                    color: #fff;
                    padding: 8px 15px;
                    border-radius: 20px;
                    border: none;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
        .flash-sale-button-box{
            padding-top: 15px;
            text-align: center;

            .flash-sale-button{
                width: 298px;
                height: 35px;
                background: linear-gradient(0deg, #F3D99D, #FFF3D6);
                box-shadow: 0px 5px 0px 0px #FEB04A;
                border-radius: 17.5px;
                border: none;
            }
        }
    }
}