.line{
    margin: 20px auto 0px;
}
.header{
    width: 100vw;
    margin-bottom: 13px;
    background-color: white;

    .header_top{
        padding: 0 28px;
        
        .search_box{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
            margin-bottom: 27px;
    
            .search_input{
                box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.06);
                border-radius: 17px;
                margin-right: 10px;
            }
        }
    
        .gird-nav{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 37px;
            grid-row-gap: 22px;
            text-align: center;

            .icon_bg{
                width: 51px;
                height: 51px;
                background: linear-gradient(-40deg, #C39A6C 0%, #FFCA90 100%);
                // box-shadow: 1px 6px 18px 0px rgba(230, 161, 83, 0.3);
                border-radius: 50px;
                margin: 0 auto 12px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .message_box{
        width: 100%;
        height: 40px;

        .adm-notice-bar{
            height: 40px;
            color: #A5A5A5;
            background-color: white;
            border: none;
        }

        .notice-header{
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            display: flex;
            justify-content: start;
            align-items: center;
            background: rgba(214, 160, 94, 0.12);
            height: 20px;
            border-radius: 4px;

            .notice-title{
                display: inline-block;
                color: #D6A05E;
                padding: 2px 5px;
                margin-right: 6px;
            }

            .notice-content{
                color: #A5A5A5;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }
        }
    }
}

.new_message_list{
    margin-top: 13px;
    background-color: white;

    .new_list{
        padding-left: 12px;

        .new_list_title{
            padding-right: 30px;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #414141;
            word-break:normal;
            word-wrap:break-word;
            // line-height: 40px;
        }
        >.list{
            margin: 20px auto;

            .list_title{
                padding-right: 30px;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
                font-size: 13px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #414141;
                word-break:normal;
                word-wrap:break-word;
                // line-height: 40px;
            }

            .list_info{
                display: flex;
                align-items: center;

                >p{
                    margin-right: 30px;
                    font-size: 11px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #919090;
                }
                >p:first-child {
                    margin-left: 7px;
                }
            }
            .list_answer,
            .list_vote{
                font-size: 13px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #919090;
                padding-left: 7px;
            }

            .list_activity_info{
                >p{
                    margin-right: 30px;
                    font-size: 11px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #919090;
                    margin-left: 7px;
                }
            }

            .line{
                margin: 20px auto;
            }
        }

        >div:last-child{
            margin-bottom: 0px;
            .line{
                border-top: 0px !important;
                margin-bottom: 0px;
            }
        }
    }
}