.mycenter{
    background: white;
    padding-bottom: 3.5rem;
    .headerBox{
        height: 270px;
        position: relative;
        .bg{
            width: 700px;
            height: 435px;
            border-radius: 50%;
            background: orange;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .info{
            position: absolute;
            width: 345px;
            height: 195px;
            background: #FFFFFF;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
            border-radius: 20px;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%,0);
            padding: 25px 14px;
            .top{
                margin-bottom: 36px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .avatar{
                    width: 65px;
                    height: 65px;
                    background: #F5F5F5;
                    box-shadow: 0px 3px 8px 2px rgba(45, 45, 45, 0.06);
                    border-radius: 50%;
                    margin-left: 9px;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .infoContent{
                    flex: 1;
                    display: flex;
                    align-items: start;
                    justify-content: space-between;
                    margin-left: 13px;
                    .infoLeft{
                        .user-name{
                            font-size: 18px;
                            font-family: PingFang SC;
                            font-weight: bold;
                            color: #262626;
                        }
                        .lv-name{
                            font-size: 13px;
                            font-family: PingFang SC;
                            font-weight: 500;
                            color: #666666;
                        }
                    }
                    .infoRight{
                        width: 100px;
                        height: 28px;
                        border-radius: 28px;
                        background-color: rgba(#EEEEEE, 0.47);
                        padding: 8px 9px;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        font-size: 13px;
                        font-family: PingFang SC;
                        font-weight: bold;
                        color: #515151;
                        img{
                            width: 20px;
                            height: 15px;
                            margin-right: 5px;
                        }
                    }
                }
            }
            .bottom{
                text-align: center;
                .quantity{
                    font-size: 20px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #333333;
                }
                .title{
                    font-size: 15px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }
    }
}
.meun-list{
    margin-top: 41px;
    border: none;
    .adm-list-item-content{
        border: none;
    }
}