@import "../../assets/styles/vars/index.scss";
@import "../../assets/styles/mixins/index.scss";

.comment-module{

    :global{

        .comment-module-item{

            .adm-list-item-content{
                align-items: flex-start !important;

                .adm-list-item-content-prefix{
                    padding-right: 11px;
                }

                .module-top{

                    .top{
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;

                        .nick-name{
                            font-size: 13px;
                            color: $title-color;
                        }
                        .praise-number{
                            font-size: $desc-size;
                            font-family: PingFang SC;
                            font-weight: 500;
                            color: $title-color;
                            display: flex;
                            align-items: flex-start;
                            &.isLikeOn{
                                color: $like-on-color;
                            }
                        }
                    }

                    .bottom{
                        font-size: $date-size;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #ACACAC;
                    }
                }
            }

            .comment-content{
                font-size: $content-size;
                font-family: PingFang SC;
                font-weight: 500;
                color: $comment-content-color;
                // margin-bottom: 11px;

                .release-box{
                    font-size: 13px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #6A6A6A;
                    display: flex;
                    align-items: center;
    
                    .release-name{
                        color: #5C85C2;
                    }
                }
            }

            .reply{
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #999999;
                margin-top: 11px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}



.release-box{
    width: 100%;
    height: 58px;
    padding: 12px 16px;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    z-index: 10;
    border-top: 1px solid #E8E8E8;

    >div{
        flex: 1 1 100%;
        height: 100%;
    }

    :global{

        .release-input-box{

            .release-input{
                height: 100%;
                background-color: #F2F4F5;
                border-radius: 34px;
                padding-left: 30px;

                input::placeholder{
                    font-size: 15px;
                }
            }
        }

        .count-box{
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            margin-top: -4px;

            &.isLikeOn-count{
                color: #FE686A;
            }
            &.isCollectOn-count{
                color: #F8AC2A;
            }
        }

    }
}

.reply-box{
    display: flex;
    align-items: flex-end;

    :global{

        .text-area{
            border: 1px solid #e9e9e9;
            border-radius: 6px;
            padding: 5px;
        }
        .send-button{
            margin-left: 8px;
            flex: 1 1 20%;
            background: linear-gradient(-28deg, #D6A15F, #DFB37D);
            border-radius: 18.5px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
        }
    }
}