.list_page{
    .list_tabs{
        background-color: white;
        .adm-tabs-tab-list{
            .adm-tabs-tab-active{
                color: #DAAE65
            }
            .adm-tabs-tab-active::after{
                background: #DAAE65;
            }
        }
    }
    .adm-dialog-body-message{
        width: 100%;
    }

    .form_assess{
        .submit-btn{
            width: 80%;
            height: 40px;
            border-radius: 5px;
            background: linear-gradient(-45deg,#DAAE65, #F8C4866D);
            border: none;
            color: white;
            font-weight: 600;
            margin: 0 auto;
        }
    }
    
    .stepstyle{
        width: 200px;
        .adm-steps-horizontal{
            padding: 0px;
        }
        .adm-step-status-finish{
            --icon-color: #DAAE65;
            --line-to-next-color: #DAAE65;
        }
        .adm-step-status-process{
            --icon-color: #DAAE65;
            .adm-step-title{
                color: #DAAE65;
            }
        }
    }
}

.HRAppraise{
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 10px;
}