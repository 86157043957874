.user-comment{
    margin-top: 13px;
    .comment-header{
        padding: 0px 16px;
        height: 39px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        position: relative;
        >p{
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
            margin-left: 10px;
        }

        &::before{
            content: '';
            display: inline-block;
            width: 5px;
            height: 14px;
            background: #C9A16F;
            position: absolute;
        }
    }
    .comment-box{
        background: #ffffff;
        padding: 0px 16px;
        .comment-list{
            .ant-comment-inner{
                padding-top: 0px;
            }
            .ant-comment-content{
                padding-bottom: 13px;
                border-bottom: 1px solid #E5E5E5;
            }
            li:last-child .ant-comment-content{
                border: none;
            }

            li{
                width: 100%;
            }

            .ant-comment-content-author{
                width: 100%;
                .ant-comment-content-author-name{
                    width: 100%;
                }
            }

            .list-top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .handle{
                    font-size: 20px;
                }
            }

            .reply-box{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .comment-time{
                    font-size: 11px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #999999;
                }
                .comment-reply{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    >div{
                        font-size: 12px;
                        font-family: PingFang SC;
                        font-weight: 500;
                        color: #999999;
                        img{
                            margin-right: 8px;
                        }
                    }
                    >div:first-child{
                        margin-right: 28px;
                        img{
                            vertical-align: baseline;
                        }
                    }
                }
            }
        }
    }
}

.release-comment-box{
    width: 100%;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 13px;
    position: fixed;
    bottom: 0;
    padding: 0px 16px;

    .comment-box-left{
        flex: 1;
        padding: 0px 20px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.input-focus {
            display: none;
        }

        .box-item{
            display: flex;
            flex-direction: column;
            justify-content: center;

            .iconStyle{
                width: 18px;
                height: 18px;
            }

            .font-style{
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #666666;
                text-align: center;

                &.likeCheckStyle{
                    color: #FE686A;
                }
                &.startCheckStyle{
                    color: #F8AC2A;
                }
            }
        }
    }
    .comment-box-right{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex: 2;
        .am-textarea-item{
            flex: 1;
            padding-left: 0px;
            .am-textarea-error-extra{
                margin-top: 0px;
            }
        }
        textarea{
            width: 100%;
            height: 34px;
            background: #F2F4F5;
            border-radius: 17px;
            padding: 0px 20px;
            line-height: 1.95;
            flex: 1;
        }
        .sendBtn{
            display: none;
            width: 60px;
            height: 35px;
            border-radius: 5px;
            font-size: 12px;
            // line-height: 2.9;
            background-color: #D6A05E;
            color: #ffffff;
            margin-top: 10px;
            margin-left: 16px;
            &.isShow{
                display: block;
            }
            &::before{
                border: none;
            }
        }
    }

}

.html_content {
    background: #ffffff;
    width: 100%;
    padding: 0px 10px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    video{
        width: 100%;
    }
}

.icon{
    width: 17px;
    height: 17px;
}
.ant-btn:active{
    color: #D6A05E !important;
    border-color: #D6A05E !important;
}
.ant-btn-primary{
    background: #D6A05E !important;
    border-color: #D6A05E !important;
}

.parentComment{
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #101010;
}
.childComment{
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 500;
    background: #F7F9FA;
    border-radius: 8px;
    padding: 15px 11px;
    margin-top: 14px;
    .username{
        color: #7684A8;
    }
    .item-comment{
        color: #333333;
    }
}
.more{
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #4A90E2;
    padding-bottom: 11px;
    text-align: center;
}

.collect-box{
    .adm-mask{
        z-index: 1001;
    }
    .creatFilesBtn{
        width: 80%;
        height: 30px;
        line-height: 1.8;
        text-align: center;
        margin: 10px auto 0px;
        border: 1px solid #D6A05E !important;
        color: #D6A05E !important;
        font-size: 15px;
    }
    .adm-dialog-button{
        color: #D6A05E !important;
    }
    .adm-dialog-body-message{
        width: 100%;
    }
    .adm-radio.adm-radio-checked .adm-radio-icon {
        border-color: #D6A05E;
        background-color: #D6A05E;
    }
}
.collectFilePopup{
    padding: 20px 16px;
    text-align: center;
    .collectFilePopupTitle{
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #A4A4A4;
        margin-bottom: 10px;
    }
    .collectFilePopupBox{
        .adm-grid-item{
            min-height: 35px;
            font-size: 15px;
            line-height: 2.2;
            font-family: PingFang SC;
            font-weight: 500;
            color: #202020;
        }
        .collectFileItem{
            width: 100%;
            height: 100%;
            border-color: #fff;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #202020;
            
            &:active{
                background: #F7EFE6;
                border-color: #D39C59;
                color: #D39C59;
            }
        }
    }
    .creatFiles{
        width: 100%;
        text-align: center;
        margin: 10px auto 0px;
        border: none;
        color: #D6A05E !important;
        font-size: 15px;
    }
}