$color-grey-1: #cccccc;
$at-load-more-height: 50px;
$at-load-more-tips-color: $color-grey-1;
$at-load-more-tips-size: 14px;

.at-load-more {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $at-load-more-height;
  overflow: hidden;
  box-sizing: border-box;

  &__cnt {
    flex: 1;
  }

  &__text-box {
    display: flex;
    justify-items: center;
    align-items: center;
  }
  &__tip {
    text-align: center;
    width: 100%;
    color: $at-load-more-tips-color;
    font-size: $at-load-more-tips-size;
  }
  &__line {
    display: block;
    width: 36px;
    height: 1px;
    background: #cccccc;
  }
}
