.interviewForm{
    .adm-input{
        border: 1px solid #eee;
        border-radius: 5px;
        padding-left: 8px;
    }
    .adm-text-area{
        border: 1px solid #eee;
    }
    .box{
        padding: 0px 15px;
        .adm-space-item{
            flex: 1;
        }
    }
    .submit-btn{
        width: 80%;
        height: 40px;
        border-radius: 5px;
        background: linear-gradient(-45deg,#DAAE65, #F8C4866D);
        border: none;
        color: white;
        font-weight: 600;
        margin: 0 auto;
    }
    .adm-radio-checked .adm-radio-icon{
        border-color: #DAAE65;
        background-color: #DAAE65;
    }
    .area{
        padding-right: 12px;
        .adm-list-item-content-main{
            display: flex;
            justify-content: space-between;
        }
    }
}